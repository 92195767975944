import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { isMobile } from "react-device-detect";
const Footer = (props) => {
  let [init2020, setInit2020] = useState(true);

  const setImage = (path) => {
    var ext = path.split(".")[1];
    if (isWebpSupported() && isMobile) {
      // if (isWebpSupported()) {
      switch (ext) {
        case "jpg":
        case "gif":
        case "png":
          //ext = "webp";
          break;
      }
    }
    return require("../../img/" + path.split(".")[0] + "." + ext);
  };

  const loadAnim = () => {
    if (document.getElementsByClassName("init2020").length > 0) {
      window.addEventListener("scroll", scrollCatch2020);
      let elems = document.getElementsByClassName("init2020");
      setTimeout(() => {
        if (document.getElementsByClassName("init2020").length > 0) {
          elems[0].classList.remove("init2020");
          window.removeEventListener("scroll", scrollCatch2020);
        }
      }, 10000);
    }
  };
  const scrollCatch2020 = () => {
    let elems = document.getElementsByClassName("init2020");
    elems[0].classList.remove("init2020");
    window.removeEventListener("scroll", scrollCatch2020);
  };
  useEffect(() => {}, []);
  useEffect(() => {
    !props.fastio.preLoader && loadAnim();
  }, [props]);

  return (
    <footer className={"clearfix init2020"}>
      <div className="copywright">
        <h4>© Copyright {new Date().getFullYear()} Fastio</h4>
      </div>
      <div className="connect">
        {/* <NavLink to="/project2030" className="pt2020" rel="noopener">
          <img src={setImage("footer/pt2030.png")} />
        </NavLink> */}
        <a
          href="/project2030assets/compete2030.pdf"
          className="pt2020"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={setImage("footer/pt2030.png")} alt="Project 2030" />
        </a>
        <h4>{props.fastio.langKeys.footer[props.fastio.lang].str1}</h4>
        <ul className="clearfix">
          <li className="facebook">
            <a
              href="https://www.facebook.com/aguadofastiooficial/"
              target="_blank"
              rel="noopener"
            ></a>
          </li>
          <li className="instagram">
            <a
              href="https://www.instagram.com/aguadofastio/"
              target="_blank"
              rel="noopener"
            ></a>
          </li>
          <li className="youtube">
            <a
              href="https://www.youtube.com/channel/UCJ3SvhzsF0GbEG73gegPpMA"
              target="_blank"
              rel="noopener"
            ></a>
          </li>
          <li className="email">
            <a href="mailto:fastio.net@eaa.pt"></a>
          </li>
          <li className="phone">
            <a href="tel:+351253359040"></a>
          </li>
        </ul>
      </div>
      <div id="customHomeFooter" className="custom-home-footer">
        <img
          className="background bk4"
          src={setImage("home/quote/bottlefooter4.png")}
        />
      </div>
    </footer>
  );
};

export default Footer;
