import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { isMobile } from "react-device-detect";

const Project2020 = (props) => {
  const setImage = (path) => {
    var ext = path.split(".")[1];
    if (isWebpSupported() && isMobile) {
      // if (isWebpSupported()) {
      switch (ext) {
        case "jpg":
        case "gif":
        case "png":
          ext = "webp";
          break;
      }
    }
    return require("../../img/sustainability/" +
      path.split(".")[0] +
      "." +
      ext);
  };

  const loadAnim = () => {
    let i = 0;
    let elems = document.getElementsByClassName("anim");
    for (let key of elems) {
      key.style.transitionDelay = i < 9 ? "0." + i + "s" : i / 10 + "s";
      setTimeout(() => {
        key.style.opacity = 1;
      }, 1000);
      i++;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    props.fastio.togglePreLoader();

    return () => {
      // This function will be called just before the component unmounts.
      // Remove listeners, subscriptions, etc.
    };
  }, []);

  useEffect(() => {
    !props.fastio.preLoader && loadAnim();
  }, [props]);

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://fastio.pt/sustantabilidade" />
        <meta
          name="description"
          content="A Água do Fastio é uma água mineral natural que nasce no interior da original gruta de Nossa Senhora do Fastio, nas Terras do Bouro, em plena Serra do Gerês."
        />
        <title>Projeto 2030 - Água do Fastio</title>
      </Helmet>

      <section className="project2020 anim">
        <div className="project-file">
          <div className="container">
            <div className="content">
              <h1>Ficha de Projetos</h1>

              <img src="./project2030assets/project2030_logos.png" alt="" />

              <h2>SI QUALIFICAÇÃO DE PME’S</h2>
              <ul>
                <li>
                  <h3>Designação dos projetos:</h3>
                  <span> Qualificação de PME’s</span>
                </li>
                <li>
                  <h3>Número dos projetos:</h3>
                  <span> 013574</span>
                </li>
                <li>
                  <h3>Códigos da operação:</h3>
                  <span> POCI – 02 – 0853 – FEDER – 013574</span>
                </li>
                <li>
                  <h3>Objetivo principal:</h3>
                  <span> Reforçar a competitividade das PME</span>
                </li>
                <li>
                  <h3>Região de intervenção:</h3>
                  <span>
                    {" "}
                    Norte – Unidade Territorial do Cávado – Terras de Bouro
                  </span>
                </li>
                <li>
                  <h3>Entidade beneficiária:</h3>
                  <span>
                    {" "}
                    Água do Fastio – Comércio e Engarrafamento de Águas
                    Minerais, S.A.
                  </span>
                </li>
                <li>
                  <h3>Data de aprovação:</h3>
                  <span> 23-03-2016</span>
                </li>
                <li>
                  <h3>Data de início:</h3>
                  <span> 01-12-2015</span>
                </li>
                <li>
                  <h3>Data de conclusão:</h3>
                  <span> 30-11-2017</span>
                </li>
                <li>
                  <h3>Custo total elegível:</h3>
                  <span> 348 983,27 EUR</span>
                </li>
                <li>
                  <h3>Apoio financeiro da União Europeia:</h3>
                  <span> FEDER – 157 042,47 EUR</span>
                </li>
                <li>
                  {" "}
                  <h3>Objetivos, atividades e resultados:</h3>
                </li>

                <li>
                  {" "}
                  <h3>Objetivos:</h3>
                  <p>
                    Implementação de uma infraestrutura informática que dê
                    suporte a um sistema de informação e comunicação, composto
                    por um ERP, bem como aquisição de um parque de hardware que
                    o sustente;
                  </p>
                  <p>
                    Implementação de um sistema de gestão de qualidade, NP EN
                    ISO 9001;
                  </p>
                  <p>Inserção da empresa na economia digital.</p>
                </li>
                <li>
                  {" "}
                  <h3>Atividades:</h3>
                  <p> Introdução de novos métodos e sistemas de organização;</p>
                  <p>
                    Desenvolvimento e implementação de plataformas WEB
                    empresariais.
                  </p>
                </li>

                <li>
                  {" "}
                  <h3>Resultados:</h3>
                  <p>
                    Implementação de um ERP de gestão (incluindo CRM) coerente
                    com as exigências da gestão moderna e competitiva;
                  </p>
                  <p>
                    Desenvolvimento e implementação de um portal multilingue.
                  </p>
                </li>
              </ul>
              {/*<img src="./project2030assets/project2030_logos.png" alt="" />

             <h2>SI INTERNACIONALIZAÇÃO DE PME’S</h2>
              <ul>
                <li>
                  <h3>Designação dos projetos:</h3>
                  <span> Internacionalização de PME’s</span>
                </li>
                <li>
                  <h3>Número dos projetos:</h3>
                  <span> 013556</span>
                </li>
                <li>
                  <h3>Códigos da operação:</h3>
                  <span> POCI – 02 – 0853 – FEDER – 013556</span>
                </li>
                <li>
                  <h3>Objetivo principal:</h3>
                  <span> Reforçar a competitividade das PME</span>
                </li>
                <li>
                  <h3>Região de intervenção:</h3>
                  <span>
                    {" "}
                    Norte - Unidade Territorial do Cávado – Terras de Bouro
                  </span>
                </li>
                <li>
                  <h3>Entidade beneficiária:</h3>
                  <span>
                    {" "}
                    Água do Fastio – Comércio e Engarrafamento de Águas
                    Minerais, S.A.
                  </span>
                </li>
                <li>
                  <h3>Data de aprovação:</h3>
                  <span> 23-03-2016</span>
                </li>
                <li>
                  <h3>Data de início:</h3>
                  <span> 01-12-2015</span>
                </li>
                <li>
                  <h3>Data de conclusão:</h3>
                  <span> 30-11-2017</span>
                </li>
                <li>
                  <h3>Custo total elegível:</h3>
                  <span> 127 617,11 EUR</span>
                </li>
                <li>
                  <h3>Apoio financeiro da União Europeia:</h3>
                  <span> FEDER – 57 427,70 EUR</span>
                </li>
                <li>
                  <h3>Objetivos, atividades e resultados:</h3>
                </li>

                <li>
                  <h3>Objetivos:</h3>
                  <p>
                    Conquista de uma presença ativa no mercado global, tendo
                    como base a promoção e venda de água mineral natural FASTIO
                    de origem nacional, sustentada numa gama diversificada de
                    apresentações da marca, apostando em fatores dinâmicos de
                    competitividade e em modelos empresariais inovadores com
                    vista a promover as exportações no sentido de assegurar
                    ganhos de forma rápida, eficiente e eficaz no contexto
                    internacional, com opções que, na ótica da empresa, se
                    revelam inovadoras em matéria de marketing e organizacional.
                  </p>
                </li>

                <li>
                  <h3>Atividades:</h3>
                  <p>
                    Conhecimento de mercados externos, através de participações
                    em feiras internacionais em mercados alvo;
                  </p>
                  <p>
                    Marketing internacional, nomeadamente através da concepção e
                    desenvolvimento de panfletos promocionais para o mercado
                    externo.
                  </p>
                </li>
                <li>
                  <h3>Resultados:</h3>
                  <p>
                    Desenvolvimento de novos contactos internacionais com o
                    incremento de imagem de marca e correspondente obtenção de
                    novos mercados.
                  </p>
                </li>
              </ul>*/}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Project2020;
