import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Translator from "./translations/fastio";

import Header from "./components/Header/header";
import Home from "./components/Home/home";
import Kids from "./components/Kids/kids";
import Go from "./components/Go/go";
import Sustainability from "./components/Sustainability/sustainability";
import History from "./components/History/history";
import Explore from "./components/Explore/explore";
import Contact from "./components/Contact/contact";
import Project2020 from "./components/Project2020/project2020";
import Nav from "./components/Nav/nav";
import Footer from "./components/Footer/footer";
// import NotFound from './components/NotFound/notFound'
import "./app.css";

const App = () => {
  const systemLang = window.navigator.userLanguage || window.navigator.language;
  const langKeys = Translator;
  const langs = ["pt", "en", "es", "fr"];

  let [lang, setLang] = useState("pt");

  let [preLoader, setPreLoader] = useState(true);

  const togglePreLoader = (status) => {
    status
      ? setPreLoader(true)
      : setTimeout(() => {
          setPreLoader(false);
        }, 3500);
  };

  const switchLang = (newLang) => {
    setLang(newLang);
  };

  let fastio = {
    lang,
    langs,
    langKeys,
    switchLang,
    preLoader,
    togglePreLoader,
  };
  useEffect(() => {
    switch (systemLang.split("-")[0]) {
      case "en":
        setLang("en");
        break;
      case "pt":
        setLang("pt");
        break;
      case "fr":
        setLang("fr");
        break;
      case "es":
        setLang("es");
        break;
      default:
        setLang("pt");
    }
  }, []);
  return (
    <BrowserRouter>
      <Nav fastio={fastio} />
      <Header fastio={fastio} />
      <Routes>
        <Route exact path="/" element={<Home fastio={fastio} />} />
        <Route path="/home" element={<Home fastio={fastio} />} />
        <Route path="/kids" element={<Kids fastio={fastio} />} />
        <Route path="/go" element={<Go fastio={fastio} />} />
        <Route
          path="/sustentabilidade"
          element={<Sustainability fastio={fastio} />}
        />
        <Route path="/historia" element={<History fastio={fastio} />} />
        <Route path="/explore" element={<Explore fastio={fastio} />} />
        <Route path="/contacto" element={<Contact fastio={fastio} />} />
        <Route path="/project2030" element={<Project2020 fastio={fastio} />} />
        <Route path="*" element={<Home fastio={fastio} />} />
      </Routes>

      <Footer fastio={fastio} />
    </BrowserRouter>
  );
};

export default App;
